<script setup lang="ts">
import { mdiAccountOutline, mdiBellOutline, mdiChartBoxOutline, mdiHomeOutline } from '@mdi/js'

const { t } = useI18n();

const { formatLink } = useInternationalization(useLocalePath())

//TODO replace icons
const items = computed(() => [
  {
    title: t('investorPortal.navigation.dashboard'),
    icon: mdiHomeOutline,
    to: '/investor-portal'
  },
  {
    title: t('investorPortal.navigation.investments'),
    icon: mdiChartBoxOutline,
    to: '/investor-portal/investments'
  },
  {
    title: t('investorPortal.navigation.profile'),
    icon: mdiAccountOutline,
    to: '/investor-portal/profile'
  },
  {
    title: t('investorPortal.navigation.news'),
    icon: mdiBellOutline,
    to: '/investor-portal/news'
  },
]);
</script>

<template>
  <v-navigation-drawer :permanent="$vuetify.display.mdAndUp" mobile-breakpoint="md" :width="$vuetify.display.lgAndUp ? 280 : 220">
    <v-list class="px-4 py-8">
      <v-list-item v-for="(item, i) of items" :key="i" :to="formatLink(item.to)">
        <v-list-item-title>{{ item.title }}</v-list-item-title>
        <template v-slot:prepend>
          <v-icon color="gray500">{{ item.icon }}</v-icon>
        </template>
      </v-list-item>
    </v-list>
    <template v-slot:append>
      <v-card class="ma-4" title="placeholder" color="gray100" :border="0" variant="tonal"/>
    </template>
  </v-navigation-drawer>
</template>
